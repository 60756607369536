body {
  background-color: #282c34;
  color: white;
  text-align: center;
}

header,
.social-section {
  display: flex;
}

header {
  flex-direction: column;
}

h1 {
  padding-right: .5em;
}

.social-section {
  justify-content: flex-end;
  position: relative;
  top: -1em;
}

header > a:hover, .banner-section > div {
  background-color: rgba(0,0,0,.5);
}

.banner-section > a:hover {
  background-color: rgba(0,0,0,.6);
}

img:hover{
  opacity: 0.6;
}

@media screen and (min-width: 768px) {
  header,
  .social-section {
    flex-direction: row;
    justify-content: space-between;
    position: unset;
  }
}

.logo {
  width: 100px;
}

.icon {
  width: 50px;
  height: 40px;
  border-radius: 10px;
  margin: 0 5px;
  background-color: white;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.banner-section {
  display: flex;
  flex-direction: column;
}

.banner-section img {
  width: 100vw;
  height: 35vh;
}

@media screen and (min-width: 768px) {
  .banner-section img {
    height: 40vh;
  }
}

@media screen and (min-width: 1024px) {
  .social-section {
    position: unset;
  }
  .banner-section {
    flex-direction: row;
  }
  .banner-section img {
    width: 100%;
    height: 78vh;
  }
}

.banner-section div {
  position: relative;
  display: flex;
  align-items: center;
}

h2 {
  position: absolute;
  right: 1em;
  font-size: 5em;
}

footer {
  margin: 1em;
}
